<template>
  <v-expansion-panels accordion flat>
    <v-expansion-panel
      v-for="step in steps"
      :key="`step-${step.step}`"
      :class="`card-bg-step-${step.step}`">
      <base-section
        :step="step.step" 
        :filled-percent="step.filledPercent"
        :title="step.title"
        :sub-title="step.subTitle"
        :content="step.content"
        :without-description="step.withoutDescription"
        :secondButton="step.secondButton"
        :polygon-with-icon="step.polygonWithIcon"
        :step-decorator="step.stepDecorator" />
    <v-expansion-panel-content flat class="ma-0" v-if="!step.withoutDescription">
      <expansion-content
        class="ma-0"
        :step="step.step"
        :with-items="step.items || []"
        :description="step.moreDataDescription"
        :more-data-with-news-button="step.moreDataWithNewsButton" />
    </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'AccordionNosotros',
  components: {
    BaseSection: () => import('@/views/components/BaseSection.vue'),
    ExpansionContent: () => import('@/views/components/ExpansionContent')
  },
  data() {
    return {
      steps: [
        {
          step: '01',
          filledPercent: '0',
          title: '¿QUÉ ES TOKENFIT?',
          content: `
              <p class="font-weight-bold">
                TOKENFIT es una sociedad que en una primera etapa tiene por finalidad investigar la Tokenización de activos y su comercialización utilizando tecnología Blockchain en el marco de la normativa argentina. 
                TOKENFIT quiere impulsar la adopción de tecnología blockchain acelerando la conjunción del sector de Real Estate con los nuevos modelos de negocios basados en Web3.
              </p>
              <p>  
                TOKENFIT realizará la primera experiencia de venta de un inmueble en Argentina utilizando íntegramente tecnología Blockchain. 
                Esto generará precedentes en un camino poco explorado, probando límites y necesidades del sistema jurídico y tecnológico Argentino.
              </p>`,
          withoutDescription: true,
          secondButton: 'INSCRIBITE A NUESTRAS NEWS',
          polygonWithIcon: 'assets/tokenfit-nosotros-step-01-icon.svg',
          stepDecorator: 'nosotros'
        },
        {
          step: '02',
          filledPercent: '0',
          title: 'NUESTROS OBJETIVOS',
          content: `
              <p><b>Contribuir</b> a una adecuada y moderna regulación del <b>sistema</b> jurídico y tecnológico <b>argentino</b> para favorecer el desarrollo de nuevos modelos de negocios con <b>+ Transparencia + Descentralización + Accesibilidad.</></p>
              <p>Explorar para las empresas fundadoras de TOKENFIT estrategias disruptivas de crecimiento y maduración del negocio.</p>`,
          withoutDescription: true,
          polygonWithIcon: 'assets/tokenfit-nosotros-step-02-icon.svg',
          stepDecorator: 'nosotros'
        }    
      ]
    }
  }

  

}
</script>

<style>

</style>